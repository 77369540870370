import { property } from 'lit-element';
import { BaseElement } from '../base/BaseElement';

type Size = 's' | 'm';

type Hierarchy = 'first' | 'second';

type Emphasis = 'default' | 'selected';

/**
 * This interface for content-tab-items contains those properties that
 * are used by the content-tab-bar.
 */
export interface ContentTabItem {
  disabled: boolean;
  emphasis: Emphasis;
  value: string;

  hierarchy?: Hierarchy;
  size?: Size;
}

/**
 * Abstract base class for all content-tab-items that are used in the content-tab-bar.
 */
export abstract class ContentTabItemBase extends BaseElement implements ContentTabItem {
  /**
   * marker to know at runtime that this element is a content-tab-item.
   *
   * @private
   */
  @property({ reflect: true, type: Boolean, attribute: 'zui-internal-is-content-tab-item' })
  readonly isContentTabItem = true as const;

  /**
   * It is possible to set the attribute: disabled.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines one of three possible emphasis states (default/selected).
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Value is used to identify tab item (i.e. close event).
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * It is possible to use this attribute to show the close icon in the component.
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * Text to be shown for content tab item
   */
  @property({ reflect: true, type: String })
  text: string;

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this._handleClick);
  }

  private _handleClick(event): void {
    if (this.disabled) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }
}
