import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import style from './step-indicator-item.component.scss';

const stepIndicatorItemStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = 'next' | 'selected' | 'visited';
type Size = 's' | 'm';

/**
 * The `zui-step-indicator-item` is part of the `zui-step-indicator-bar` and visualizes it's state (visited/selected/next) by the given emphasis.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=76965%3A368673)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45219%3A117)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-step-indicator-item text="One" value="one"></zui-step-indicator-item>
 * ```
 */
@customElement('zui-step-indicator-item')
export class StepIndicatorItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, stepIndicatorItemStyles];
  }

  /**
   * the emphasis is set dependent on the parent `zui-step-indicator-bar` value and should not be set manually
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'next';

  /**
   * each `zui-step-indicator-item` gets the size set for the `zui-step-indicator-bar` and should not be set manually
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * the step indicator item text that is displayed inside the item
   */
  @property({ reflect: true, type: String })
  text: string;

  /**
   * the value is used to identify the `zui-step-indicator-item` in a list of `zui-step-indicator-item`'s
   */
  @property({ reflect: true, type: String })
  value: string;

  protected render(): TemplateResult {
    return html`<div class="step-indicator-item-text">${this.text}</div>`;
  }
}
