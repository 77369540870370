import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';

import style from './menubar-divider.component.scss';
const menuBarDividerStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The menu bar divider keeps the zui-divider component with a fixed size and emphasis.
 * Also we override the color for the used divider to always enforce dark theme styling.
 * This is done by setting a fixed color to the dividers '--zui-color-thin-line-highlight'
 * custom property.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menubar-divider>
 * </<zui-menubar-divider>
 * ```
 */
@customElement('zui-menubar-divider')
export class MenubarDivider extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuBarDividerStyles];
  }

  protected render(): TemplateResult | void {
    return html`<zui-divider emphasis="highlight" size="s"> </zui-divider>`;
  }
}
