import { css, CSSResult, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import type { TitleStack } from '../title-stack/title-stack.component';
import styles from './title-stack-meta-item.component.scss';

const titleStackMetaItemStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The title stack meta item is used to compose additional captions of a title stack.
 * Please refer to the readme of the title stack component for further details.
 *
 * @example
 * HTML:
 *
 * A title stack with two meta items
 * ```html
 * <zui-title-stack>
 *   <zui-title-stack-meta-item slot="meta">Caption 1</zui-title-stack-meta-item>
 * </zui-title-stack>
 * ```
 *
 * @slot default - This is the default slot which should reflect the text node.
 * @cssprop --zui-title-stack-meta-item-separator-height - sets the vertical size of the item separator
 * @cssprop --zui-title-stack-meta-item-separator-width - sets the horizontal size of the item separator
 */
@customElement('zui-title-stack-meta-item')
export class TitleStackMetaItem extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, titleStackMetaItemStyles];
  }

  /**
   * Sets the size of the title stack
   */
  @property({ reflect: true })
  size: TitleStack['size'] = 'm';

  /**
   * Allows hiding the leading separator
   */
  @property({ reflect: true, attribute: 'hide-separator', type: Boolean })
  hideSeparator = false;

  protected render(): TemplateResult {
    // DO NOT ADD LINE BREAKS as it will lead to unwanted trailing white space
    // prettier-ignore
    return html`<span class="separator"></span><slot></slot>`;
  }
}
