/**
 * check if the selected index is inside the first half of the first displayed dots
 *
 * @param itemCount item count
 * @param selectedIndex the selected index
 * @param maxItems max items
 *
 * @returns {boolean} whether the selected index is inside first half or not
 */
export const atStart = (itemCount: number, selectedIndex: number, maxItems: number): boolean => {
  if (itemCount <= maxItems) {
    return true;
  }

  return selectedIndex <= Math.floor(maxItems / 2);
};

/**
 * check if the selected index is inside the last half of the last displayed dots
 *
 * @param itemCount item count
 * @param selectedIndex the selected index
 * @param maxItems max items
 *
 * @returns {boolean} whether the selected index iinside last half or not
 */
export const atEnd = (itemCount: number, selectedIndex: number, maxItems: number): boolean => {
  if (itemCount <= maxItems) {
    return true;
  }

  return selectedIndex >= itemCount - 1 - Math.floor(maxItems / 2);
};

/**
 * index based range that includes a given selected index
 *
 * @param itemCount item count
 * @param selectedIndex the selected index
 * @param maxItems length of list that should be returned
 *
 * @returns {number[]} range with length of max items
 */
export const getPaginationDots = (itemCount: number, selectedIndex: number, maxItems: number): number[] => {
  if (itemCount <= maxItems) {
    return [...new Array(itemCount).keys()];
  }

  return [...new Array(itemCount).keys()].reduce((acc, curr) => {
    const min = Math.max(0, selectedIndex - Math.floor(maxItems / 2));
    const max = Math.min(itemCount - 1, selectedIndex + Math.floor(maxItems / 2));

    const start = atEnd(itemCount, selectedIndex, maxItems) ? itemCount - 1 - (maxItems - 1) : min;
    const end = atStart(itemCount, selectedIndex, maxItems) ? maxItems - 1 : max;

    if (curr >= start && curr <= end) {
      return [...acc, curr];
    }

    return acc;
  }, []);
};
