import { customElement } from 'lit-element';
import { BaseElement } from '../base/BaseElement';

/**
 * A helper component to provide a maintained context if needed. For example the
 * tooltip requires to work properly to be in a non-disabled context. It can't
 * be injected into e.g. disabled buttons. Thus the disabled button could be
 * wrapped with this helper element to work as reference for the tooltip.
 *
 * _Note: Since divs are block elements you may have to align its styling._
 *
 * @example
 * ```html
 * <zui-div style="display: inline-block">
 *   <zui-button disabled>Click me!</zui-button>
 *   <zui-tooltip-directive>
 *     <zui-tooltip>Not allowed right now</zui-tooltip>
 *   </zui-tooltip-directive>
 * </zui-div>
 * ```
 *
 * @slot default simply projects everything inside
 */
@customElement('zui-div')
export class Div extends BaseElement {
  // set default style to mimic div
  connectedCallback(): void {
    super.connectedCallback();
    // prevent overriding custom styles
    if (this.style.getPropertyValue('display') === '') {
      this.style.setProperty('display', 'block');
    }
  }

  // if we have no shadow dom enabled, we have to inject the slotted contents as child nodes
  createRenderRoot(): Element | ShadowRoot {
    // to render into the element's childNodes, return `this`
    // https://stackoverflow.com/a/55213037/1146207
    // https://lit-element.polymer-project.org/api/classes/_lit_element_.litelement.html#createrenderroot
    // https://github.com/lit/lit-element/blob/2b398727dacb7694fbf38816824675d20838704f/src/lit-element.ts#L244-L246
    return this;
  }

  // make sure to NOT implement a render method!
  // render(): void {}
}
