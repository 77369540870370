import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  query,
  css,
  unsafeCSS,
  state,
  queryAssignedNodes,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import { classMap } from 'lit-html/directives/class-map';
import { DialogButton } from '../../dialog-button/dialog-button.component';

import style from './ewiq-dialog-buttons.component.scss';
const ewiqDialogButtonsStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * @typedef {('auto' | 'first' | 'last')} PrimaryButtonPosition
 */
type PrimaryButtonPosition = 'auto' | 'first' | 'last';

/**
 * The button container holds the dialog buttons
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-buttons primary-position="first">
 *   <zui-dialog-button slot="primary">Primary Action</zui-dialog-button>
 *   <zui-dialog-button slot="secondary">Secundary Action</zui-dialog-button>
 *   <zui-dialog-button>Tertiary Action</zui-dialog-button>
 * </zui-ewiq-dialog-buttons>
 * ```
 *
 * @slot primary - Slot for the primary buttons
 * @slot secondary - Slot for the secondary buttons
 * @slot - This is the default slot for default buttons
 */
@customElement('zui-ewiq-dialog-buttons')
export class EwiqDialogButtons extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, ewiqDialogButtonsStyles];
  }

  /**
   * ARIA role for this element; defaults to 'button' if not explicitly set by author
   */
  @property({ reflect: true })
  role = 'button';

  /**
   * this defines the position of the primary button
   */
  @property({ reflect: true, type: String, attribute: 'primary-position' })
  primaryPosition: PrimaryButtonPosition = 'auto';

  /**
   * internal property to set column / row alignment of buttons
   */
  @state()
  private _zuiInternalAlignButtonsInColumns = false;

  /**
   * internal property to control button styling
   */
  @state()
  private _primaryExists = false;

  @state()
  private _secondaryExists = false;

  /**
   * internal property to control class mapping for border styling
   */
  @state()
  private _rowReverse = false;

  @query('slot[name="primary"]')
  private _slotPrimary: HTMLSlotElement;

  @queryAssignedNodes('secondary', true, 'zui-dialog-button')
  private _assignedSecondaryDialogButtons: NodeListOf<DialogButton>;

  private get _secondaryDialogButtons(): DialogButton[] {
    return Array.from(this._assignedSecondaryDialogButtons);
  }

  private get _isEverySecondaryDialogButtonMultiline(): boolean {
    return this._secondaryDialogButtons.length > 0 && this._secondaryDialogButtons.every((button) => button.multiline);
  }

  @query('slot:not([name])')
  private _slotDefault: HTMLSlotElement;

  // sets attributes to align the buttons, as well as style the border correctly
  private _setFlexDirection(): void {
    // we use an extra attribute to handle border styling in different flex directions
    // since the flex direction is set via browser utils, we check the custom property to set the attribute

    const buttonOrder = getComputedStyle(this).getPropertyValue('--zui-ewiq-button-alignment');
    if (buttonOrder.trim() === 'row-reverse') {
      this._rowReverse = true;
    }
  }

  // set an attribute to align buttons
  private _primarySlotChangeHandler(): void {
    if (this._slotPrimary.assignedElements().length < 1) {
      return;
    }

    // whenever the slot is filled, we set the emphasis to primary-highlight
    this._slotPrimary.assignedElements()[0].setAttribute('emphasis', 'primary-highlight');

    this._primaryExists = true;
    this._setFlexDirection();
  }

  private _secondarySlotChangeHandler(): void {
    if (this._secondaryDialogButtons.length < 1) {
      this._secondaryExists = false;
      return;
    }

    this._secondaryDialogButtons.forEach((secondaryDialogButtton) => (secondaryDialogButtton.emphasis = 'highlight'));

    this._secondaryExists = true;
    this._setFlexDirection();
  }

  // set an attribute to align buttons
  private _defaultSlotChangeHandler(): void {
    if (this._slotPrimary.assignedElements().length < 1) {
      return;
    }

    // having a primary button and more than two extra buttons, we want to align them in columns
    if (this._slotDefault.assignedElements().length >= 2) {
      this._zuiInternalAlignButtonsInColumns = true;
    }
    this._setFlexDirection();
  }

  // Check for multiline attribute in slotted buttons
  // Due race conditions setting the multiline attribute, this can not be done in the slot change handlers, nor in the firstUpdate hook
  protected updated(): void {
    if (
      this._slotPrimary.assignedElements().every((slottedElement) => slottedElement.hasAttribute('multiline')) ||
      this._isEverySecondaryDialogButtonMultiline ||
      this._slotDefault.assignedElements().every((slottedElement) => slottedElement.hasAttribute('multiline'))
    ) {
      this._zuiInternalAlignButtonsInColumns = true;
    }
  }

  protected render(): TemplateResult | void {
    return html`
      <div
        id="button-container"
        class=${classMap({
          'align-buttons-in-columns': this._zuiInternalAlignButtonsInColumns,
          'primary-exists': this._primaryExists,
          'secondary-exists': this._secondaryExists,
          'row-reverse': this._rowReverse,
        })}
      >
        <slot name="primary" @slotchange="${this._primarySlotChangeHandler}"></slot>
        <slot name="secondary" @slotchange="${this._secondarySlotChangeHandler}"></slot>
        <slot @slotchange="${this._defaultSlotChangeHandler}"></slot>
      </div>
    `;
  }
}
