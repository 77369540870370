import { TemplateResult, customElement, html, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';

import style from './slider-tick-label.component.scss';
import { hostStyles } from '../../../host.styles';
const sliderTickLabelStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This represents a very simple component, which is used to pass custom label data into the slider
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-slider-tick-label>2X</zui-slider-tick-label>
 * ```
 *
 * @slot - This is the default slot to be used for the label text
 */
@customElement('zui-slider-tick-label')
export class SliderTickLabel extends BaseElement {
  static readonly styles = [hostStyles, sliderTickLabelStyles];

  protected render(): TemplateResult | void {
    return html`<slot></slot>`;
  }
}
