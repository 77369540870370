import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { queryAssignedNodes } from 'lit-element/lib/decorators';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import style from './menu-tab-icon-item.component.scss';

const menuTabIconItemStyles = css`
  ${unsafeCSS(style)}
`;

type Emhpasis = 'default' | 'selected';

/**
 * A menu tab icon item is meant to be used inside the menu tab bar.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menu-tab-icon-item emphasis="selected" value="#1">
 *   <zui-icon-laser-laser-ready></zui-icon-laser-laser-ready>
 * </zui-menu-tab-icon-item>
 * ```
 */
@customElement('zui-menu-tab-icon-item')
export class MenuTabIconItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuTabIconItemStyles];
  }

  /**
   * whether the `zui-menu-tab-icon-item` is selectable or not
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * emphasis of the `zui-menu-tab-icon-item` (default/selected)
   */
  @property({ reflect: true, type: String })
  emphasis: Emhpasis = 'default';

  /**
   * the value that is used inside the `zui-menu-tab-bar` to set the current selected `zui-menu-tab-icon-item` emphasis to selected
   */
  @property({ reflect: true, type: String })
  value: string;

  @queryAssignedNodes('', true, '[zuiIcon]')
  private _assignedMenuTabIconItemIcons: HTMLElement[];

  private _propagateMenuTabIconSize(): void {
    this._assignedMenuTabIconItemIcons.forEach((icon) => icon.setAttribute('size', 'm'));
  }

  private _handleMenuTabIconItemSlotchange(): void {
    this._propagateMenuTabIconSize();
  }

  protected render(): TemplateResult {
    return html`
      <button ?disabled="${this.disabled}" class="menu-tab-icon-item">
        <slot @slotchange="${this._handleMenuTabIconItemSlotchange}"></slot>
      </button>
    `;
  }
}
