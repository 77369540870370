import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
  state,
} from 'lit-element';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import styles from './page-header.component.scss';
import { nothing } from 'lit-html';
import { HeaderbarIconButton } from '../headerbar/headerbar-icon-button/headerbar-icon-button.component';
import { TitleStack } from '../title-stack/title-stack/title-stack.component';

const PAGE_HEADER_STYLES = css`
  ${unsafeCSS(styles)}
`;

/**
 * Page Headers are used at the top of a page and contain the top-level information of the current page.
 * The information can be displayed with a headline or a Title Stack.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-page-header>
 *  <zui-title-stack size="m">
 *    <h1>Headline</h1>
 *    <zui-icon-sex-sex-other slot="icon"></zui-icon-sex-sex-other>
 *    <zui-title-stack-meta-item slot="meta">Meta Data</zui-title-stack-meta-item>
 *    <zui-title-stack-meta-item slot="meta">Date of Birth</zui-title-stack-meta-item>
 *    <zui-title-stack-meta-item slot="meta">ID Number</zui-title-stack-meta-item>
 *  </zui-title-stack>
 *  <zui-headerbar-icon-button emphasis="subtle" size="m" slot="iconButton">
 *    <zui-icon-placeholder slot="icon"></zui-icon-placeholder>
 *  </zui-headerbar-icon-button>'
 * </zui-page-header>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the page-header-element
 * @slot iconButton - This is the slot for the zui-headerbar-icon-button
 * @cssprop --zui-page-header-height - sets the overall height of the page header
 */
@customElement('zui-page-header')
export class PageHeader extends BaseElement {
  static readonly styles: CSSResultArray = [hostStyles, PAGE_HEADER_STYLES];

  /**
   * This disables the PageHeader
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  @queryAssignedNodes('iconButton', true, 'zui-headerbar-icon-button')
  private _assignedHeaderbarIconButtons: HeaderbarIconButton[];

  @queryAssignedNodes('', true, 'zui-title-stack')
  private _assignedTitleStacks: TitleStack[];

  @state()
  private _hasIcon = false;

  private _handleIconSlotChange(): void {
    // force specific icon size
    this._assignedHeaderbarIconButtons.forEach((icon) => (icon.size = 'm'));
    // check for existence of slotted icons
    this._hasIcon = this._assignedHeaderbarIconButtons.length > 0;
  }

  private _handleDefaultSlotchange(): void {
    this._assignedTitleStacks.forEach((titleStack) => (titleStack.wrapping = false));
  }

  protected render(): TemplateResult | void {
    return html`
      <slot name="iconButton" @slotchange="${this._handleIconSlotChange}"></slot>
      ${this._hasIcon ? html` <zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>` : nothing}
      <slot @slotchange="${this._handleDefaultSlotchange}"></slot>
    `;
  }
}
