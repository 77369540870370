// for the time being, this is just a tagging decorator used for annotation eventDispatchMethods
// in a hopefully not so far future, this will either be implemented by LitElement itself or someone
// dare to write a small third-party library doing exactly this... :)
// see https://github.com/Polymer/lit-element/issues/896 for more context

export interface EventOptions {
  /**
   * A string custom event name to override the default.
   */
  eventName?: string;

  /**
   * A Boolean indicating whether the event bubbles up through the DOM or not.
   */
  bubbles?: boolean;

  /**
   * A Boolean indicating whether the event is cancelable.
   */
  cancelable?: boolean;

  /**
   * A Boolean value indicating whether or not the event can bubble across the boundary between the shadow DOM and the regular DOM.
   */
  composed?: boolean;
}

/**
 * @param eventOptionsOrName
 * @param eventOptions
 */
// eslint-disable-next-line func-style
export function event(eventOptionsOrName: EventOptions | string, eventOptions?: EventOptions) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return (target, propKey) => {};
}
