import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import styles from './picker-header.component.scss';
import { event } from '../../../decorators/event.decorator';
import { InteractiveIcon } from '../../interactive-icon/interactive-icon.component';

const headerStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The picker header component is a UI sub component of the date picker component. It shows a string and two buttons.
 * When the string or the buttons get pressed events get emited.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-header value="June 2021">
 *  <zui-interactive-icon slot="icon-left">
 *   <zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left>
 *  </zui-interactive-icon>
 *  <zui-interactive-icon slot="icon-right">
 *   <zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right>
 *  </zui-interactive-icon>
 * </zui-picker-header>
 * ```
 * @fires {CustomEvent} picker-header-previous-selected - event which gets emitted when the left icon gets clicked.
 * @fires {CustomEvent} pickerHeaderPreviousSelected - (Deprecated) event which gets emitted when the left icon gets clicked.
 * @fires {CustomEvent} picker-header-current-selected - event which gets emitted when the text gets clicked.
 * @fires {CustomEvent} pickerHeaderCurrentSelected - (Deprecated) event which gets emitted when the text gets clicked.
 * @fires {CustomEvent} picker-header-next-selected - event which gets emitted when the right icon gets clicked.
 * @fires {CustomEvent} pickerHeaderNextSelected - (Deprecated) event which gets emitted when the right icon gets clicked.
 *
 * @slot icon-left - This is the slot for the left icon.
 * @slot icon-right - This is the slot for the right icon.
 */
@customElement('zui-picker-header')
export class PickerHeader extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, headerStyles];
  }

  /**
   * Text of the picker header
   */
  @property({ reflect: true, type: String })
  value = '';

  /**
   * Disabled the text of the picker header
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Emits a custom changed event when the left icon is selected
   *
   * @private
   */
  @event({
    eventName: 'picker-header-previous-selected',
    bubbles: true,
    composed: false,
  })
  emitPickerHeaderPreviousSelectedEvent(): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('pickerHeaderPreviousSelected', {
        bubbles: true,
        composed: false,
      })
    );

    this.dispatchEvent(
      new CustomEvent('picker-header-previous-selected', {
        bubbles: true,
        composed: false,
      })
    );
  }

  /**
   * Emits a custom changed event when the right icon is selected
   *
   * @private
   */
  @event({
    eventName: 'picker-header-next-selected',
    bubbles: true,
    composed: false,
  })
  emitPickerHeaderNextSelectedEvent(): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('pickerHeaderNextSelected', {
        bubbles: true,
        composed: false,
      })
    );

    this.dispatchEvent(
      new CustomEvent('picker-header-next-selected', {
        bubbles: true,
        composed: false,
      })
    );
  }

  /**
   * Emits a custom changed event when the text is selected
   *
   * @private
   */
  @event({
    eventName: 'picker-header-current-selected',
    bubbles: true,
    composed: false,
  })
  emitPickerHeaderCurrentSelectedEvent(): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('pickerHeaderCurrentSelected', {
        bubbles: true,
        composed: false,
      })
    );

    this.dispatchEvent(
      new CustomEvent('picker-header-current-selected', {
        bubbles: true,
        composed: false,
      })
    );
  }

  @queryAssignedNodes('icon-left', true, 'zui-interactive-icon')
  private _assignedInteractiveIconsLeft: InteractiveIcon[];

  @queryAssignedNodes('icon-right', true, 'zui-interactive-icon')
  private _assignedInteractiveIconsRight: InteractiveIcon[];

  /**
   * Adds subtle emphasis to the slotted left interactive icon
   *
   */
  private _handleIconLeftSlotchange(): void {
    this._assignedInteractiveIconsLeft.forEach((icon) => (icon.emphasis = 'subtle'));
  }

  /**
   * Adds subtle emphasis to the slotted right interactive icon
   *
   */
  private _handleIconRightSlotchange(): void {
    this._assignedInteractiveIconsRight.forEach((icon) => (icon.emphasis = 'subtle'));
  }

  protected render(): TemplateResult | void {
    return html`<div id="flex-wrapper"
      ><div class="icon-placeholder"
        ><slot
          id="left-icon-slot"
          name="icon-left"
          @click=${this.emitPickerHeaderPreviousSelectedEvent}
          @slotchange=${this._handleIconLeftSlotchange}
        ></slot
      ></div>
      <button
        id="text-button"
        ?disabled=${this.disabled}
        tabindex="0"
        @click=${this.emitPickerHeaderCurrentSelectedEvent}
        >${this.value}</button
      ><div class="icon-placeholder"
        ><slot
          id="right-icon-slot"
          name="icon-right"
          @click=${this.emitPickerHeaderNextSelectedEvent}
          @slotchange=${this._handleIconRightSlotchange}
        ></slot></div
    ></div>`;
  }
}
