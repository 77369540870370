import { css, CSSResult, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { hostStyles } from '../../host.styles';
import styles from './avatar.component.scss';

type Size = '24' | '32' | '40' | '64' | '128';

const avatarStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The avatar component displays a circle, with a customized background color
 * and customized centered initials - or with a centered background image in different sizes.
 * If the user specifies an image url, there will be no background colors nor initials.
 *
 * ## Figma
 * - [Web - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/❖-04-Web---Component-Library---1.4?node-id=284%3A0)
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=4426%3A0)
 *
 * @example
 * HTML:
 *
 * Avatar with background color and initials
 * ```html
 * <zui-avatar initials="zw" size="64" style="--zui-avatar-color-background: var(--zui-color-sc-b-110);"></zui-avatar>
 * ```
 *
 * Avatar with an image
 * ```html
 * <zui-avatar image-url="https://i.postimg.cc/3NG0WwMW/random-Face.jpg" size="32"></zui-avatar>
 * ```
 *
 * @cssprop --zui-avatar-color-background - background color of the avatar
 * @cssprop --zui-avatar-diameter - circle diameter
 */
@customElement('zui-avatar')
export class Avatar extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, avatarStyles];
  }

  /**
   * Sets size of the AvatarComponent
   */
  @property({ reflect: true })
  size: Size = '40';

  /**
   * Sets initials of the AvatarComponent
   */
  @property({ reflect: true, type: String })
  initials = '';

  protected _imageUrl = '';

  /**
   * Sets image path of the AvatarComponent
   */
  @property({ attribute: 'image-url', reflect: true, type: String })
  get imageUrl(): string {
    return this._imageUrl;
  }
  set imageUrl(value: string) {
    const oldImageUrl = this._imageUrl;
    this._imageUrl = value;
    this.requestUpdate('imageUrl', oldImageUrl);
    this.requestUpdate('_imageUrlLegacy', oldImageUrl);
  }

  /**
   * @deprecated
   * [DO NOT USE] Sets the image path of the AvatarComponent (with the wrong `imageurl`/`imageUrl` attribute)
   */
  @property({ attribute: 'imageurl', reflect: true, type: String })
  get _imageUrlLegacy(): string {
    return this._imageUrl;
  }
  set _imageUrlLegacy(value: string) {
    console.warn('Deprecated attribute imageUrl on component\n%o\n\nUse image-url instead!', this);

    const oldImageUrl = this._imageUrl;
    this._imageUrl = value;
    this.requestUpdate('imageUrl', oldImageUrl);
  }

  protected render(): TemplateResult {
    return html`
      <div> ${this.imageUrl ? html`<img src="${this.imageUrl}" />` : html`<span>${this.initials}</span></div>`} </div>
    `;
  }
}
