import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import style from './ewiq-dialog-description.component.scss';
const ewiqDialogDescriptionStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This represents a very simple description component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled div container
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-description>Test</zui-ewiq-dialog-description>
 * ```
 *
 * @slot - This is the default slot to be used for the description text
 */
@customElement('zui-ewiq-dialog-description')
export class EwiqDialogDescription extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, ewiqDialogDescriptionStyles];
  }

  protected render(): TemplateResult | void {
    return html`<div tabindex="0"><slot></slot></div>`;
  }
}
