import { css, CSSResult, customElement, html, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import styles from './pill-content.component.scss';

const pillContentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * A grouping UI component that displays the content of a pill
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-pill-content></zui-pill-content>
 * ```
 *
 * @slot - default slot for content
 * @slot icon - slot for a state dot or an icon
 * @slot iconClose - slot for a close icon within an interactive icon
 */
@customElement('zui-pill-content')
export class PillContent extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, pillContentStyles];
  }

  protected render(): TemplateResult {
    return html`
      <slot></slot>
      <slot name="icon"></slot>
      <slot name="iconClose"></slot>
    `;
  }
}
