import { CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS, css } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import styles from './headerbar-product-name.component.scss';
import { classMap } from 'lit-html/directives/class-map';

const productNameStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The zui-headerbar-product-name is a sub component of the headerbar which can show a product name and a product sub name.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library"></zui-headerbar-product-name>
 * ```
 */
@customElement('zui-headerbar-product-name')
export class HeaderbarProductName extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, productNameStyles];
  }

  /**
   * Defines the product name
   */
  @property({ reflect: true, type: String, attribute: 'product-name' })
  productName = '';

  /**
   * Defines the product sub name
   */
  @property({ reflect: true, type: String, attribute: 'product-sub-name' })
  productSubName = '';

  protected render(): TemplateResult | void {
    return html`<div id="flex-wrapper"
      ><div id="ellipsis-wrapper" class="${classMap({ 'has-product-sub-name': this.productSubName?.length > 0 })}"
        ><span id="product-name">${this.productName}</span
        ><span id="product-sub-name">${this.productSubName}</span></div
      ></div
    >`;
  }
}
