import {
  css,
  customElement,
  html,
  property,
  PropertyValues,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import type { Placement } from '@popperjs/core/lib/enums';

import { event } from '../../../decorators/event.decorator';
import type { Menu } from '../../menu/menu/menu.component';

import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import style from './select-menu.component.scss';

const POPPER_PLACEMENT_DATA_ATTRIBUTE_NAME = 'data-popper-placement';

const SELECT_MENU_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * This element brings animations to toggleable select menus. For convenience it is
 * separated, it is a simple wrapper around a slotted menu and will itself be
 * projected most likely into a portal. It manages the open and close animations
 * and notifies about those processes.
 *
 * @example
 * ```HTML
 * <zui-overlay-directive flip>
 *   <zui-select-menu open>
 *     <zui-menu>
 *       <zui-menu-item>I'm a select menu now!</zui-menu-item>
 *     </zui-menu>
 *   </zui-select-menu>
 * </zui-overlay-directive>
 * ```
 *
 * @fires select-menu-open - broadcasts if menu starts opening
 * @fires select-menu-opened - broadcasts if menu is fully opened
 * @fires select-menu-close - notifies about menu closing
 * @fires select-menu-closed - notifies about menu being completely closed
 *
 * @slot - default slot for an optional divider label
 * @cssprop --zui-select-menu-animation-duration - duration of the menu toggle animation
 * @cssprop --zui-select-menu-overflow - offset before invisible overflow, prevents cut-offs of the menu shadow
 */
@customElement('zui-select-menu')
export class SelectMenu extends BaseElement {
  static readonly styles = [hostStyles, SELECT_MENU_STYLES];

  /**
   * Either show or hide the menu, triggers animations.
   */
  @property({ reflect: true, type: Boolean })
  open = false;

  /**
   * Broadcasts if menu starts opening.
   *
   * @private
   */
  @event({ eventName: 'select-menu-open', bubbles: true, composed: true })
  emitOpenEvent(): void {
    this.dispatchEvent(new CustomEvent('select-menu-open', { bubbles: true, composed: true }));
  }

  /**
   * Broadcasts if menu is fully opened.
   *
   * @private
   */
  @event({ eventName: 'select-menu-opened', bubbles: true, composed: true })
  emitOpenedEvent(): void {
    this.dispatchEvent(new CustomEvent('select-menu-opened', { bubbles: true, composed: true }));
  }

  /**
   * Notifies about menu closing.
   *
   * @private
   */
  @event({ eventName: 'select-menu-close', bubbles: true, composed: true })
  emitCloseEvent(): void {
    this.dispatchEvent(new CustomEvent('select-menu-close', { bubbles: true, composed: true }));
  }

  /**
   * Notifies about menu being completely closed.
   *
   * @private
   */
  @event({ eventName: 'select-menu-closed', bubbles: true, composed: true })
  emitClosedEvent(): void {
    this.dispatchEvent(new CustomEvent('select-menu-closed', { bubbles: true, composed: true }));
  }

  /**
   * @private
   */
  @property({ reflect: true, type: String, attribute: POPPER_PLACEMENT_DATA_ATTRIBUTE_NAME })
  private readonly _zuiPlacement: Placement;

  @state()
  private _running = false;

  @queryAssignedNodes(undefined, true, 'zui-menu')
  private readonly _menuRef: Menu[];

  private _handleTransitionEnd(): void {
    // the animation is over
    this._running = false;
    // trigger corresponding event
    if (this.open) {
      this.emitOpenedEvent();
    } else {
      this.emitClosedEvent();
    }
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // everytime the open property is changed, we need to to toggle
    // a class for the CSS animation to be run (as we're re-using
    // the same animation for both transitions, just in reverse)
    if (changedProperties.has('open')) {
      this._running = true;
      // trigger corresponding event
      if (this.open) {
        this.emitOpenEvent();
      } else {
        this.emitCloseEvent();
      }
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    // we're observing a data attribute which is manipulated by
    // the positioning library in order to update the placement
    // of the nested menu element
    if (changedProperties.has('_zuiPlacement')) {
      this._menuRef[0].placement = this._zuiPlacement;
    }
  }

  protected render(): TemplateResult {
    return html`
      <div id="clipper">
        <div
          id="wrapper"
          class="${classMap({ open: this.open, close: !this.open, running: this._running })}"
          @animationcancel="${this._handleTransitionEnd}"
          @animationend="${this._handleTransitionEnd}"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }
}
