import { customElement, html, TemplateResult } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';

/**
 * A structural element to be used within the `zui-select` feature component to separate options.
 * It has neither styling nor functional implementations whatsoever. The optional slotted content
 * will be passed further into the UI divider.
 *
 * @example
 * ```HTML
 * <zui-select-divider>An optional label</zui-select-divider>
 * ```
 *
 * @slot - default slot for an optional divider label
 */
@customElement('zui-select-divider')
export class SelectDivider extends BaseElement {
  protected render(): TemplateResult {
    return html` <slot></slot> `;
  }
}
