import { css, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';

import type { EventWithTarget } from '../../../types';
import { isSlotNotEmpty } from '../../../utils/component.utils';
import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import style from './menu-divider.component.scss';

const MENU_DIVIDER_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * A UI component mainly being used by the `zui-select` feature component internally. It encapsulates all styling purposes of the menu
 * divider feature, which can be used declaratively passing `zui-select-divider`.
 *
 * ## Functionality
 * It internally checks for a projected label through the default slot and sets a flag accordingly. Also, it cuts off labels which are
 * too long using three d...
 *
 * @example
 * ```HTML
 * <zui-menu-divider>Optional label</zui-menu-divider>
 * ```
 *
 * @slot - default slot to provide an optional divider label
 * @cssprop --zui-menu-divider-color - color of the divider label
 * @cssprop --zui-menu-divider-height - overall height of the divider
 * @cssprop --zui-menu-divider-line-color - color of the divider line
 * @cssprop --zui-menu-divider-line-height - minimum height of the line to be enforced even without label
 * @cssprop --zui-menu-divider-line-padding - offset from to label if necessary
 */
@customElement('zui-menu-divider')
export class MenuDivider extends BaseElement {
  static readonly styles = [hostStyles, MENU_DIVIDER_STYLES];

  /**
   * internal property reflecting in attribute used for styling
   *
   * @private
   */
  @property({ attribute: 'has-label', reflect: true, type: Boolean })
  hasLabel: boolean;

  private _checkHasLabel({ target }: EventWithTarget<HTMLSlotElement>): void {
    this.hasLabel = isSlotNotEmpty(target);
  }

  protected render(): TemplateResult {
    return html`
      <span class="line">
        <span class="label">
          <slot @slotchange="${this._checkHasLabel}"></slot>
        </span>
      </span>
    `;
  }
}
