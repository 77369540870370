import {
  CSSResult,
  TemplateResult,
  css,
  customElement,
  html,
  property,
  queryAssignedNodes,
  unsafeCSS,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import type { StateDot } from '../../state/state-dot/state-dot.component';
import { hostStyles } from '../../../host.styles';
import styles from './headerbar-notification-button.component.scss';

type Emphasis = 'default' | 'selected';
type Size = 'm' | 'l';

const headerbarNotificationButtonStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * HeaderbarNotificationButton component displays a slotted icon and a slotted state dot. The state dot is shown when the `has-notifications` attribute is set.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-notification-button emphasis="default" has-notifications size="m">
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *   <zui-state-dot slot="stateDot"></zui-state-dot>
 * </zui-headerbar-notification-button>
 * ```
 *
 * @slot icon - Slot for an icon
 * @slot stateDot - Slot for a state dot
 */
@customElement('zui-headerbar-notification-button')
export class HeaderbarNotificationButton extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, headerbarNotificationButtonStyles];
  }

  /**
   * Emphasis of the HeaderbarNotificationButton
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Whether to show the state dot or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'has-notifications' })
  hasNotifications = false;

  /**
   * Size of the HeaderbarNotificationButton
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  @queryAssignedNodes('stateDot', true, 'zui-state-dot')
  private _assignedStateDots: NodeListOf<StateDot>;

  private _handleStateDotSlotchange(): void {
    this._assignedStateDots.forEach((stateDot) => (stateDot.size = 'xs'));
  }

  protected render(): TemplateResult {
    return html`
      <zui-headerbar-icon-button emphasis="${this.emphasis}" size="${this.size}">
        <slot name="icon" slot="icon"></slot>
      </zui-headerbar-icon-button>
      <slot name="stateDot" @slotchange="${this._handleStateDotSlotchange}"></slot>
    `;
  }
}
