import { CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS, css } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import styles from './header-cell.component.scss';
import { BaseElement } from '../../base/BaseElement';

export const headerCellStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The picker header cell is sub component of the date picker which can be reused in all picker widgets. A text can get set with the attribute text.
 * It has two CSS variables which can overwrite the width and height of the header-cell: --zui-picker-header-cell-width and --zui-picker-header-cell-height
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-header-cell text="MO"></zui-picker-header-cell>
 * ```
 * @cssprop --zui-picker-header-cell-width - Overwrites the width of the picker-header-cell
 * @cssprop --zui-picker-header-cell-height - Overwrites the height of the picker-header-cell
 */
@customElement('zui-picker-header-cell')
export class HeaderCell extends BaseElement {
  /**
   * Text of the picker header cell
   */
  @property({ reflect: true, type: String })
  text = '';

  /**
   * Value of the picker header cell
   */
  @property({ reflect: true, type: String })
  value = '';

  static get styles(): CSSResultArray {
    return [hostStyles, headerCellStyles];
  }

  protected render(): TemplateResult | void {
    return html`<div class="text-wrapper">${this.text}</div>`;
  }
}
