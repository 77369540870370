import { traverseDOMSiblingsByStepAndDirection } from '../../../utils/dom.utils';

/**
 * gets a previous gridCell by a certain step length
 * @param currentGridCell
 * @param step
 * @returns the previous GridCell matching criteria
 */
export function getPreviousPickerGridCell(currentGridCell: HTMLElement, step = 1): null | HTMLElement {
  return traverseDOMSiblingsByStepAndDirection(
    currentGridCell,
    'previous',
    step,
    (element) => element.tagName === currentGridCell.tagName && !element.hasAttribute('disabled')
  );
}

/**
 * gets a previous gridCell by a certain step length
 * @param currentGridCell
 * @param step
 * @returns the next GridCell matching criteria
 */
export function getNextPickerGridCell(currentGridCell: HTMLElement, step = 1): null | HTMLElement {
  return traverseDOMSiblingsByStepAndDirection(
    currentGridCell,
    'next',
    step,
    (element) => element.tagName === currentGridCell.tagName && !element.hasAttribute('disabled')
  );
}
