import {
  css,
  CSSResult,
  customElement,
  html,
  property,
  query,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../host.styles';
import styles from './drag-drop-area.component.scss';
import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';

const dragDropAreaStyles = css`
  ${unsafeCSS(styles)}
`;

type Hierarchy = 'first' | 'second' | 'third';
type Emphasis = 'default' | 'active' | 'active-primary';

/**
 * The drag and drop area represents an area to drag and drop files for uploading
 * It features a text, an icon and/ or a button and has different styling
 * states of emphasis and hierarchies
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-drag-drop-area
 *  emphasis="active"
 *  hierarchy="first"
 *  style="--zui-drag-drop-area-height: 320px; --zui-drag-drop-area-width: 320px;
 *  >
 *  <span>Drag and drop here</span>
 * </zui-drag-drop-area>
 *
 * const dragDropArea = document.querySelector("zui-drag-drop-area");
 * dragDropArea.addEventListener("drop", (e) => {
 *  console.log(event.dataTransfer.items);
 *  console.log(event.dataTransfer.files);
 *  }
 * )
 * ```
 *
 * @cssprop --zui-drag-drop-area-button-icon-padding - spacing of the icon button
 * @cssprop --zui-drag-drop-area-default-height - height to be used if not given explicitly
 * @cssprop --zui-drag-drop-area-default-width - width to be used if not given explicitly
 * @cssprop --zui-drag-drop-area-font-family - sets the font family
 * @cssprop --zui-drag-drop-area-has-button-font-family - alternative font for usage with button
 * @cssprop --zui-drag-drop-area-height - Overwrites the height of the drag and drop area
 * @cssprop --zui-drag-drop-area-width - Overwrites the width of the drag and drop area
 * @fires dragenter - this event fires, when the user drags a file to the area
 * @fires dragover - this event fires permanently, when the user drags a file and moves the mouse
 * @fires dragleave - this event fires, when the user drags a file out of the area
 * @fires drop - this event fires, when the user drops a file "into" the area
 * https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
 *
 */
@customElement('zui-drag-drop-area')
export class DragDropArea extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, dragDropAreaStyles];
  }

  /**
   * The hierarchy level of the component
   */
  @property({ reflect: true, type: String })
  hierarchy: Hierarchy = 'first';

  /**
   * The emphasis of the component
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Disabled state of the component
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Drag status of the component
   */
  @property({ reflect: true, type: Boolean })
  dragging: boolean;

  /**
   * Emits the internal dragover event
   */
  @event({ eventName: 'dragover', bubbles: true, composed: true })
  emitDragoverEvent(): void {
    // this method is only here to signal the existence of the dragenter event
  }

  /**
   * Emits the internal dragenter event
   */
  @event({ eventName: 'dragenter', bubbles: true, composed: true })
  emitDragenterEvent(): void {
    // this method is only here to signal the existence of the dragenter event
  }

  /**
   * Emits the internal dragleave event
   */
  @event({ eventName: 'dragleave', bubbles: true, composed: true })
  emitDragleaveEvent(): void {
    // this method is only here to signal the existence of the dragleave event
  }

  @queryAssignedNodes('button', true, 'zui-button')
  private _assignedButtons: NodeListOf<HTMLElement>;

  @queryAssignedNodes('icon', true)
  private _assignedIcons: NodeListOf<HTMLElement>;

  @query('.content')
  private _innerContent: HTMLDivElement;

  private _originalEmphasis: Emphasis;

  // we set the listeners globally since we faced some event
  // overlapping troubles, setting them on the outer container
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('dragenter', this._handleDragEnter);
    this.addEventListener('dragover', this._handleDragOver);
    this.addEventListener('dragleave', this._handleDragLeave);
    this.addEventListener('drop', this._handleDrop);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('dragenter', this._handleDragEnter);
    this.removeEventListener('dragover', this._handleDragOver);
    this.removeEventListener('dragleave', this._handleDragLeave);
    this.removeEventListener('drop', this._handleDrop);
  }

  /**
   * Handler for the dragover event
   *
   * @param event - the dragover event
   */
  private _handleDragOver(event: Event): void {
    event.preventDefault();

    if (this.disabled) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  /**
   * Handler for the internal dragenter event
   *
   * @param event - the dragenter event
   */
  private _handleDragEnter(event: Event): void {
    event.preventDefault();

    if (this.disabled) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }

    if (!this._originalEmphasis) {
      this._originalEmphasis = this.emphasis;
    }

    // avoid button interactivity while dragging over area
    this.dragging = true;

    this.emphasis = 'active';
  }

  /**
   * Handler for the internal dragleave event
   *
   * @param event - the dragleave event
   */
  private _handleDragLeave(event: Event): void {
    event.preventDefault();

    if (this.disabled) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    this.dragging = false;
    this.emphasis = this._originalEmphasis;
  }

  /**
   * Handler for the drop event
   *
   * @param event - the drop event
   */
  private _handleDrop(event: Event): void {
    event.preventDefault();

    if (this.disabled) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      return;
    }

    this.dragging = false;
    this.removeAttribute('no-interaction');

    this.emphasis = this._originalEmphasis;
  }

  // if there is a zui-button in the button slot, we add a class for font styling
  // if there is an icon and a button, we do not display the button(s)
  private _handleSlotChange(): void {
    if (Array.from(this._assignedButtons).length) {
      this._innerContent.classList.add('has-button');
    } else if (this._innerContent.classList.contains('has-button')) {
      this._innerContent.classList.remove('has-button');
    }

    if (Array.from(this._assignedIcons).length) {
      this._assignedButtons.forEach((x) => x.remove());
    }
  }

  private _handleButtonMouseEnter(): void {
    this.setAttribute('button-hovered', '');
  }

  private _handleButtonMouseLeave(): void {
    this.removeAttribute('button-hovered');
  }

  protected render(): TemplateResult {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg">
        <rect
          width="100%"
          height="100%"
          fill="none"
          stroke="grey"
          stroke-width="2"
          stroke-dasharray="4,5"
          stroke-dashoffset="0"
          stroke-linecap="square"
        />
      </svg>
      <div class="content">
        <span class="icon"><slot name="icon"></slot></span>
        <slot></slot>
        <slot
          name="button"
          @mouseenter="${this._handleButtonMouseEnter}"
          @mouseleave="${this._handleButtonMouseLeave}"
          @slotchange="${this._handleSlotChange}"
        ></slot>
      </div>
    `;
  }
}
