import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import style from './ewiq-dialog-headline.component.scss';
const ewiqDialogHeadlineStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This represents a very simple headline component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled h1 tags
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-headline>Test</zui-ewiq-dialog-headline>
 * ```
 *
 * @slot - This is the default slot to be used for the headline text
 */
@customElement('zui-ewiq-dialog-headline')
export class EwiqDialogHeadline extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, ewiqDialogHeadlineStyles];
  }
  /**
   * ARIA role for this element; defaults to 'heading' if not explicitly set by author
   */
  @property({ reflect: true })
  role = 'heading';

  protected render(): TemplateResult | void {
    return html`<h1 tabindex="0"><slot></slot></h1>`;
  }
}
