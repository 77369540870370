import { CSSResultArray, TemplateResult, customElement, html, property, query, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { hostStyles } from '../../host.styles';
import { event } from '../../decorators/event.decorator';
import { ifDefined } from 'lit-html/directives/if-defined';
import style from './dialog-button.component.scss';

const dialogButtonStyles = css`
  ${unsafeCSS(style)}
`;

// todo: remove deprecated 'primary-active' in version 2.0 as it is replaced with 'primary-highlight'
type Emphasis = 'default' | 'highlight' | 'primary-active' | 'primary-highlight';

/**
 * The zui-dialog-button component offers a styled button, which can be single or multilined. It's meant to be used e.g in
 * the zui-ewiq-dialog and should be wrapped into the zui-ewiq-button-container component
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2768)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=39755%3A336102)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-dialog-button>Test</zui-dialog-button>
 * ```
 */
@customElement('zui-dialog-button')
export class DialogButton extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, dialogButtonStyles];
  }

  /**
   * Sets the disabled state of the dialogButton
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines one of three possible emphasis
   * Deprecated primary-active will be removed in version 2.0
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Sets the multiline state of the dialogButton
   */
  @property({ reflect: true, type: Boolean })
  multiline = false;

  /**
   * emits an click Event
   *
   * @param {DialogButton} detail is the payload
   * @private
   */
  @event({
    eventName: 'click',
    bubbles: true,
    composed: true,
  })
  emitClickEvent(detail: this): void {
    this.dispatchEvent(
      new CustomEvent('click', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @query('slot[name="secondary"]')
  private _secondarySlot: HTMLSlotElement;

  /**
   * Handler for the click event on the button, fires an event.
   *
   * @param {MouseEvent} event the click event
   */
  private _handleButtonClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.emitClickEvent(this);
  }

  /**
   * Determines if a slot contains an element and and innerHtml to set the multiline attribute
   *
   * @param {HTMLSlotElement} slot to be checked
   */
  private _checkMultiline(slot): void {
    if (!slot.assignedElements()[0]) {
      return;
    }
    if (slot.assignedElements()[0].innerHTML.length > 0) {
      this.multiline = true;
    }
  }

  protected firstUpdated(): void {
    this._checkMultiline(this._secondarySlot);
  }

  protected render(): TemplateResult {
    return html`
      <!-- this button wrapper receives keyboard-only focus -->
      <button id="button" @click="${this._handleButtonClick}" ?disabled="${this.disabled}">
        <!-- mouse-focus always arrives here; all style goes on this element to make the hitbox as large as possible -->
        <span class="button-content" tabindex="${ifDefined(this.disabled ? undefined : '-1')}">
          <section id="first-line">
            <slot></slot>
          </section>
          <section id="secondary-line">
            <slot name="secondary"></slot>
          </section>
        </span>
      </button>
    `;
  }
}
