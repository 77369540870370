import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import style from './menu-tab-text-item.component.scss';

const menuTabTextItemStyles = css`
  ${unsafeCSS(style)}
`;

type Emhpasis = 'default' | 'selected';
type Size = 's' | 'm';

/**
 * A menu tab text item is meant to be used inside the menu tab bar.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menu-tab-text-item
 *   emphasis="selected"
 *   size="s"
 *   text="Tab text item">
 * </zui-menu-tab-text-item>
 * ```
 *
 * @cssprop --zui-menu-tab-text-item-height - override default heights defined by size s or m
 */
@customElement('zui-menu-tab-text-item')
export class MenuTabTextItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuTabTextItemStyles];
  }

  /**
   * whether the `zui-menu-tab-text-item` is selectable or not
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * emphasis of the `zui-menu-tab-text-item` (default/selected)
   */
  @property({ reflect: true, type: String })
  emphasis: Emhpasis = 'default';

  /**
   * content that is displayed inside the tab text item
   */
  @property({ reflect: true, type: String })
  text = '';

  /**
   * size
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * the value that is used inside the `zui-menu-tab-bar` to set the current selected `zui-menu-tab-text-item` emphasis to selected
   */
  @property({ reflect: true, type: String })
  value: string;

  protected render(): TemplateResult {
    return html`
      <button ?disabled="${this.disabled}" class="menu-tab-text-item">
        <div class="menu-tab-text-item-text default">${this.text}</div>
        <div class="menu-tab-text-item-text selected">${this.text}</div>
      </button>
    `;
  }
}
