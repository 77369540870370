import {
  css,
  CSSResult,
  customElement,
  html,
  property,
  PropertyValues,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import styles from './user-menu-button.component.scss';
import { BaseElement } from '../../base/BaseElement';
import type { Avatar } from '../../avatar/avatar.component';
import { nothing } from 'lit-html';

type Emphasis = 'default' | 'selected';
type Size = 'm' | 'l';

const userMenuButtonStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * User menu button that can display a user name with an optional role and an avatar.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=3279%3A57420&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-user-menu-button size="l" user-name="Name" user-role="Role">
 *   <zui-avatar initials="ZW" slot="avatar"></zui-avatar>
 * </zui-user-menu-button>
 * ```
 *
 * @slot avatar - Slot for an avatar. Button size 'm' has a '32' sized avatar and button size 'l' a '40' sized.
 */
@customElement('zui-user-menu-button')
export class UserMenuButton extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, userMenuButtonStyles];
  }

  /**
   * Whether to only show the avatar of the UserMenuButton or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'avatar-only' })
  avatarOnly = false;

  /**
   * Controls the avatar only styling, when it's triggered externally
   * The component has got two avatar only attributes. Either the user sets 'avatar-only' explicitly, to hide user
   * details, or the component is used in other components, where the user details may be hidden because of space
   * availability. To handle both cases, external triggering is reflected by 'avatar-only-externally'.
   *
   * @private
   */
  @property({ reflect: true, type: Boolean, attribute: 'avatar-only-externally' })
  avatarOnlyExternally = false;

  /**
   * Disabled state of the UserMenuButton
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Emphasis of either `default` or `selected` of the UserMenuButton
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Size of either `m` or `l` of the UserMenuButton
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * User name for the UserMenuButton
   */
  @property({ reflect: true, type: String, attribute: 'user-name' })
  userName = '';

  /**
   * Optional user role for the UserMenuButton
   */
  @property({ reflect: true, type: String, attribute: 'user-role' })
  userRole = '';

  @queryAssignedNodes('avatar', true, 'zui-avatar')
  private _assignedAvatars: NodeListOf<Avatar>;

  private _updateAvatarSize(): void {
    this._assignedAvatars.forEach((avatar) => (this.size === 'l' ? (avatar.size = '40') : (avatar.size = '32')));
  }

  private _handleAvatarSlotChange(): void {
    this._updateAvatarSize();
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('size')) {
      this._updateAvatarSize();
    }
  }

  protected render(): TemplateResult {
    return html`
      <button ?disabled="${this.disabled}" type="button">
        <div class="user">
          ${this.userRole ? html`<div class="user-role">${this.userRole}</div>` : nothing}
          <div class="user-name">${this.userName}</div>
        </div>
        <slot name="avatar" @slotchange="${this._handleAvatarSlotChange}"></slot>
      </button>
    `;
  }
}
