import { css, customElement, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';

import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import style from './error-message.component.scss';

const ERROR_MESSAGE_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * A UI component for showing error messages espacially in relation with form elements like `zui-textfield`.
 * It is used internally to add error messages to form elements.
 *
 * @example
 * ```HTML
 * <zui-error-message>Something went wrong.</zui-error-message>
 * ```
 *
 * @slot - default slot to provide an error message
 */
@customElement('zui-error-message')
export class ErrorMessage extends BaseElement {
  static readonly styles = [hostStyles, ERROR_MESSAGE_STYLES];

  protected render(): TemplateResult {
    return html`<slot></slot>`;
  }
}
