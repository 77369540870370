import { CSSResultArray, TemplateResult, customElement, html, property, query, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import { isSlotNotEmpty } from '../../utils/component.utils';
import style from './thumbnail.component.scss';

const thumbnailStyles = css`
  ${unsafeCSS(style)}
`;

type Hierarchy = 'first' | 'second' | 'third';
type Emphasis = 'default' | 'active' | 'active-primary';

/**
 * Description of thumbnail
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=8006%3A103292)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102419)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-thumbnail></zui-thumbnail>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the thumbnail-element
 * @slot header - This is the slot for header. It's an innerHtml of the thumbnail-element
 * @cssprop --zui-thumbnail-border-color - color of the thumbnail border
 * @cssprop --zui-thumbnail-border-width - stroke width of the thumbnail border
 * @cssprop --zui-thumbnail-header-placeholder-bg-color - color of the placeholder background in the header
 * @cssprop --zui-thumbnail-header-placeholder-border - border style of the header placeholder
 * @cssprop --zui-thumbnail-image-placeholder-bg-color - background color of the image placeholder
 * @cssprop --zui-thumbnail-image-placeholder-border-color - color of the image placeholder border
 * @cssprop --zui-thumbnail-image-placeholder-border-width - stroke width of the thumbnail image placeholder border
 * @cssprop --zui-thumbnail-image-placeholder-top-border-width - stroke width of the thumbnail image placeholder top border
 * @cssprop --zui-thumbnail-top-line-display - allows toggling visibility of the thumbnail top line
 * @cssprop --zui-thumbnail-top-line-height - vertical size of the thumbnail top line
 */
@customElement('zui-thumbnail')
export class Thumbnail extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, thumbnailStyles];
  }

  /**
   * Defines the hierarchy for this thumbnail.
   */
  @property({ reflect: true })
  hierarchy: Hierarchy = 'first';

  /**
   * Defines one of three possible emphasis states (default/active/active-primary);
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'default';

  /**
   * This disables the thumbnail
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  @query('#header-slot')
  private _slotHeader: HTMLSlotElement;

  @query('slot[name=placeholder-icon]')
  private _slotPlaceHolderIcon: HTMLSlotElement;

  @query('#image-placeholder')
  private _imagePlaceholder: HTMLDivElement;

  private _sizeObserver: ResizeObserver = new ResizeObserver(() => {
    requestAnimationFrame(() => {
      this._setIconSizeToPlaceholder();
    });
  });

  private _calcIconSize() {
    const thumbnailWidth = this.offsetWidth;
    const thumbnailHeight = this.offsetHeight;

    if (thumbnailWidth < 116 || thumbnailHeight < 116) {
      return 'm';
    }
    if (thumbnailWidth <= 192 || thumbnailHeight <= 192) {
      return 'xl';
    }
    return '3xl';
  }

  /**
   * Set size of icon in the placeholder icon slot to the given size.
   *
   * @param {string} size the size which the icon should have
   */
  private _setIconSize(size) {
    Array.from(this._slotPlaceHolderIcon.assignedNodes())
      .filter((node) => node instanceof HTMLElement)
      .forEach((node: HTMLElement) => {
        node.setAttribute('size', size);
      });
  }

  /**
   * Set header attribute value according to the presence of slotted nodes
   */
  private _slotChangeHandler(): void {
    if (isSlotNotEmpty(this._slotHeader)) {
      this.setAttribute('zui-internal-has-header', '');
    } else {
      this.removeAttribute('zui-internal-has-header');
    }
  }

  /**
   * Tracks if the overlay-top slot is used.
   *
   * @param {Event} event the event which is fired when the overlay-top slot has changed
   */
  private _overlayTopSlotChangeHandler(event: Event): void {
    if (isSlotNotEmpty(event.target as HTMLSlotElement)) {
      this._imagePlaceholder.classList.add('has-overlay-top');
    } else {
      this._imagePlaceholder.classList.remove('has-overlay-top');
    }
  }

  /**
   * Tracks if the overlay-bottom slot is used.
   *
   * @param {Event} event the event which is fired when the overlay-bottom slot has changed
   */
  private _overlayBottomSlotChangeHandler(event: Event): void {
    if (isSlotNotEmpty(event.target as HTMLSlotElement)) {
      this._imagePlaceholder.classList.add('has-overlay-bottom');
    } else {
      this._imagePlaceholder.classList.remove('has-overlay-bottom');
    }
  }

  /**
   * Tracks if the default slot is used.
   *
   * @param {Event} event the event which is fired when the default slot has changed
   */
  private _defaultSlotChangeHandler(event: Event): void {
    if (isSlotNotEmpty(event.target as HTMLSlotElement)) {
      this._imagePlaceholder.classList.add('has-image');
    } else {
      this._imagePlaceholder.classList.remove('has-image');
    }
  }

  /**
   * Set the icon size on the icon slot.
   */
  private _setIconSizeToPlaceholder() {
    const iconSize = this._calcIconSize();
    this._setIconSize(iconSize);
  }

  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._sizeObserver.observe(this);
  }

  protected render(): TemplateResult | void {
    return html`<figure>
      <figcaption id="header-placeholder">
        <slot id="header-slot" name="header" @slotchange=${this._slotChangeHandler}></slot>
      </figcaption>
      <div id="top-line"></div>
      <div id="image-placeholder">
        <slot id="default-slot" @slotchange=${this._defaultSlotChangeHandler}></slot>
        <div id="placeholder-icon">
          <slot name="placeholder-icon" @slotchange="${this._setIconSizeToPlaceholder}"></slot>
        </div>
        <div id="overlay-top">
          <slot name="overlay-top" @slotchange=${this._overlayTopSlotChangeHandler}></slot>
        </div>
        <div id="overlay-bottom">
          <slot name="overlay-bottom" @slotchange=${this._overlayBottomSlotChangeHandler}></slot>
        </div>
      </div>
    </figure>`;
  }
}
