type Content = 'text-only' | 'text-icon' | 'icon-only';

/**
 * determine content mode based on the slotted icons and the defined text.
 *
 * @param slottedIcons - the icon elements from the slot. This can be null which is the case for the initial rendering.
 * @param text the text property
 */
export const getContentClass = (slottedIcons: HTMLElement[] | null, text: string): Content | '_' => {
  const isIconSlotEmpty = slottedIcons ? slottedIcons.length <= 0 : true;
  // When default slot is empty and icon slot is not empty then property content ist set to 'icon-only'
  if (!isIconSlotEmpty && !text) {
    return 'icon-only';
  }
  // When icon slot is empty and default slot is not empty then property content is set to 'text-only'
  if (isIconSlotEmpty && text) {
    return 'text-only';
  }
  // When icon slot is not empty and default slot is not empty then property content is set to 'text-icon'
  if (!isIconSlotEmpty && text) {
    return 'text-icon';
  }
  return '_';
};
