import { CSSResultArray, TemplateResult, customElement, html, unsafeCSS, css } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import style from './ewiq-dialog-question.component.scss';
const ewiqDialogQuestionStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This represents a very simple question text component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled span tags
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-question>How much is the fish?</zui-ewiq-dialog-question>
 * ```
 * @slot - This is the default slot to be used for the question text
 */
@customElement('zui-ewiq-dialog-question')
export class EwiqDialogQuestion extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, ewiqDialogQuestionStyles];
  }

  protected render(): TemplateResult | void {
    return html`<span tabindex="0"><slot></slot></span>`;
  }
}
