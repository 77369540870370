export type EwiState = 'error' | 'warning' | 'info' | 'question' | 'check';

export type IconSetName = 'common' | 'meditec';
export type IconName = `zui-icon-${any}`;
export type IconSet = Record<EwiState, IconName>;
export const iconSets: Record<IconSetName, IconSet> = {
  common: {
    check: 'zui-icon-ewi-check',
    error: 'zui-icon-ewi-error-triangle',
    info: 'zui-icon-ewi-info',
    question: 'zui-icon-ewi-question',
    warning: 'zui-icon-ewi-warning-triangle',
  },
  meditec: {
    check: 'zui-icon-ewi-check',
    error: 'zui-icon-ewi-error-round',
    info: 'zui-icon-ewi-info',
    question: 'zui-icon-ewi-question',
    warning: 'zui-icon-ewi-warning-round',
  },
};
