import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import style from './list-item.component.scss';

type Emhpasis = 'default' | 'active' | 'active-primary';
type Hierarchy = 'first' | 'second' | 'third';
type Size = 'xs' | 's' | 'm' | 'l' | 'xl';

const listItemStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * ListItem is used to represent an item in a list.
 * It must be contained in a parent element: list, tree-list or accordion control.
 *
 * ## Figma
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=13761%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-list-item hierarchy="first" emphasis="active" size="xl">
 * 		Content
 * </zui-list-item>
 * ```
 *
 * @slot - This is the default slot. It's an innerHtml of the ListItem-element
 */
@customElement('zui-list-item')
export class ListItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, listItemStyles];
  }

  /**
   * This disables the ListItem
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines the emphasis for this list item.
   */
  @property({ reflect: true })
  emphasis: Emhpasis = 'default';

  /**
   * Defines the hierarchy for this list item.
   */
  @property({ reflect: true })
  hierarchy: Hierarchy = 'first';

  /**
   * Defines if the item is read-only or not.
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * Defines if the item is integrated or not.
   */
  @property({ reflect: true, type: Boolean })
  integrated = false;

  /**
   * Defines one of five possible sizes ('xs' / 's' / 'm' / 'l' / 'xl')
   */
  @property({ reflect: true })
  size: Size = 'm';

  /**
   * Defines if the item is custom or not.
   */
  @property({ reflect: true, type: Boolean })
  custom = false;

  protected render(): TemplateResult | void {
    return html`<div id="content-wrapper"><slot></slot></div>`;
  }
}
