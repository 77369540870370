import { CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS, css } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { hostStyles } from '../../host.styles';
import style from './section-divider.component.scss';

const sectionDividerStyles = css`
  ${unsafeCSS(style)}
`;

const MINIMUM_WIDTH = 559;

type HeaderAlignment = 'left' | 'center';

/**
 * A divider is a horizontal line that can be used to define a thematic break.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=20971%3A190657)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=805%3A198897)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-section-divider header-alignment="center">
 *   <span>Text Content</span>
 * </zui-section-divider>
 * ```
 */
@customElement('zui-section-divider')
export class SectionDivider extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, sectionDividerStyles];
  }

  /**
   * Defines one of two possible positions of the header text alignment (left/center);
   */
  @property({ reflect: true, attribute: 'header-alignment' })
  headerAlignment: HeaderAlignment = 'left';

  private _widthObserver: ResizeObserver = new ResizeObserver(() => {
    const sectionDividerWidth: number = this.offsetWidth;

    if (sectionDividerWidth > MINIMUM_WIDTH) {
      this.setAttribute('force-left-alignment', '');
    } else {
      this.removeAttribute('force-left-alignment');
    }
  });

  /**
   * Adds width Observer to the Section Divider component
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._widthObserver.observe(this);
  }

  protected render(): TemplateResult | void {
    return html`<slot></slot>`;
  }
}
