import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import style from './ewiq-dialog-content.component.scss';
import { hostStyles } from '../../../host.styles';

const EWIQ_CONTENT_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * This represents a very simple content box component, which is used in other components, e.g. EwiqDialog.
 * It provides a pre-styled span container with a default slot.
 *
 * @example
 * HTML:
 *
 * ```html
 *  <zui-ewiq-dialog-content>Content</zui-ewiq-dialog-content>
 *
 * ```
 *
 * @slot - This is the default slot, to keep the content
 */
@customElement('zui-ewiq-dialog-content')
export class EwiqDialogContent extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, EWIQ_CONTENT_STYLES];
  }

  protected render(): TemplateResult | void {
    return html`<div tabindex="0"><slot></slot></div>`;
  }
}
