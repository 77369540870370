/**
 *  @typedef {('xs' | 's' | 'm' | 'l' | 'xl')} Size
 */
export type Size = 'xs' | 's' | 'm' | 'l' | 'xl';

/**
 * @typedef {('default' | 'active' | 'active-primary')} Emphasis
 */
export type Emphasis = 'default' | 'active' | 'active-primary';

/**
 * @typedef {( 'first' | 'second' | 'third' )} Hierarchy
 */
export type Hierarchy = 'first' | 'second' | 'third';

export type EventWithTarget<T extends Element, E extends Event = Event> = E & { target: T };

export type EventWithCurrentTarget<T extends Element, E extends Event = Event> = E & { currentTarget: T };

export type Position = {
  x: number;
  y: number;
};

export type ValidationResult = {
  message?: string;
  isValid: boolean;
};

export type ValidationCallback<T = string> = (value: T) => ValidationResult;

export enum Breakpoint {
  // <= 767px
  XS = 'XS',
  // 768px - 1023px
  S = 'S',
  // 1024px - 1279px
  M = 'M',
  // >= 1280
  L = 'L',
}

// Form stuff

export interface SimpleFormParticipated {
  formResetCallback(): void;

  formSubmitCallback(submit: Event): void;

  formDataCallback(formDataEvent: { formData: FormData }): void;
}

export type SimpleFormParticipation<T = string> = SimpleFormParticipated & {
  value: T;
  disabled: boolean;
  readonly: boolean;
  name: string;
};
